import React from 'react';
import { PageProps, graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { GetNotFoundDataQuery } from '../graphql-types';
import 'twin.macro';
import { css } from '@emotion/react';
import BaseLayout from '../layouts/base';

class NotFound extends React.Component<PageProps<GetNotFoundDataQuery>> {
  constructor(props: PageProps) {
    super(props);
  }

  render() {
    const data = this.props.data;
    const logo = data?.logo?.childrenImageSharp[0]?.fluid;

    return (
      <BaseLayout logo={logo}>
        <Helmet>
          <title>Halaman Tidak Ditemukan</title>
        </Helmet>
        <section
          css={css`
            min-height: 150px;
          `}
        >
          <div tw="container">
            <div tw="mx-8 lg:mx-32 mt-28 lg:mt-16">
              <div tw="mt-40 text-center">
                <div>
                  <h2>Halaman Tidak Ditemukan</h2>
                  <Link to="/">kembali ke beranda</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BaseLayout>
    );
  }
}

export default NotFound;

export const query = graphql`
  query getNotFoundData {
    logo: file(relativePath: { eq: "images/logo.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 130) {
          ...FileImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        ...SiteMetadataFields
      }
    }
  }
`;
